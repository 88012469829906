import { GridV2, Skeleton } from "sparta";

export function SkeletonLoader() {
  return (
    <GridV2 columns={{ initial: "1", md: "3" }} gap="2">
      {Array.from({ length: 12 }).map((_, index) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        <Skeleton key={index} height="175px" />
      ))}
    </GridV2>
  );
}
