import { RefinementListProps, useClearRefinements, useRefinementList } from "react-instantsearch";
import { Box, HoverCard, MultiSelect, Text } from "sparta";

interface RefinementMultiSelectProps extends RefinementListProps {
  leftIcon?: React.ReactNode;
  tooltip?: string;
}

export function RefinementMultiSelect({ leftIcon, tooltip, ...props }: RefinementMultiSelectProps) {
  const { items, refine, searchForItems } = useRefinementList(props);
  const { refine: clear } = useClearRefinements({
    includedAttributes: [props.attribute],
  });

  const component = (
    <Box width="min-content">
      <MultiSelect
        options={items}
        selectedValues={items.filter((i) => i.isRefined)}
        onOptionToggle={(value) => refine(value)}
        onSearchChange={searchForItems}
        onClear={clear}
        placeholder={props.searchablePlaceholder}
        shouldFilter={false}
        leftIcon={leftIcon}
      />
    </Box>
  );
  if (tooltip) {
    return (
      <HoverCard.Root>
        <HoverCard.Trigger>{component}</HoverCard.Trigger>
        <HoverCard.Content style={{ zIndex: 1 }}>
          <Text size="2">{tooltip}</Text>
        </HoverCard.Content>
      </HoverCard.Root>
    );
  }
  return component;
}
